import * as React from "react";
import { Link, graphql } from "gatsby";

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  return <>{JSON.stringify(posts)}</>;
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { slug: { regex: "/(blog/ja/)/" } }
        frontmatter: { showOnBogunov: { eq: true } }
      }
      limit: 1
    ) {
      nodes {
        html
        fields {
          slug
        }
        excerpt
        frontmatter {
          date(formatString: "YYYY/MM/DD")
          title
          showOnBogunov
          description
          featuredImage {
            publicURL
          }
        }
      }
    }
  }
`;
